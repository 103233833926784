<template>
  <div class="doc-outter">
    <div class="doc-title">
        <h3>旅客退改规定</h3>
    </div>
    <div class="doc-content">
        <div style="height: 200px;">
            <a href="/refund-doc.pdf" target="_blank" rel="noopener noreferrer">幸福航空国内航班多等级舱位管理规定（20版）.pdf</a>
        </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
a {
    text-decoration: underline;
}
</style>
